<template>
    <div style="width: 100%; height: auto">
    </div>
</template>
<script>
import VueGridLayout from "vue-grid-layout";

export default {
    name: 'Dashboard',
    components: {GridLayout: VueGridLayout.GridLayout, GridItem: VueGridLayout.GridItem},
};
</script>
<style lang="scss" scoped>
</style>
